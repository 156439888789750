.greeting-card h3 {
    font-size: 16px;
    font-weight: 600;
}
.greeting-card-info{
 line-height:normal !important
}

.cards-main{
    background:#fdf1f3;
    padding-top:4rem;
}
.cards-main .card-title{
    text-transform: uppercase;
        font-family: 'lulocleanw01-oneboldregular';
        font-weight: 700;

}
.card-btn-main{
    position: absolute;
    bottom: 0;
    left:50%;
    transform: translate(-50%, 0);
}

.card-main-image{
    position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
}
.card-main-content{
    line-height: 1.4;
    color: #160f18;
}
.cards-main .card-btn-main {
    width: 100%;
    text-align: center;
}

.cards-main .theme-dark__btn:hover{
    background:#fff !important
}
.modal-content-main h2{
    text-transform: uppercase;
        font-family: 'lulocleanw01-oneboldregular';
        font-weight: 700;
        font-size: revert-layer;
}


.modal-close-main {
    position: absolute;
    right: 20px;
    bottom: -18px;
}
.modal_tabing .nav-tabs .nav-link {
    background-color: transparent;
    border-width: 2px  !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    margin: 0 30px 0 0;
}

.modal_tabing nav a{
    text-transform: uppercase;
    font-family: 'lulocleanw01-oneboldregular';
    font-weight: 700;
    color: #160f18;
    padding: 8px 0;
    font-size: 15px;
    position: relative;
}
.modal_tabing nav .nav-link.active:after, .modal_tabing nav .nav-link:hover:after{
        position: absolute;
        content: "";
        left: 0;
        bottom: -5px;
        background: #f8c8d0;
        width: 50px;
        height: 3px;
        width: 100%;
}
.modal_tabing .nav-tabs {
    border-bottom: 3px solid #dee2e6 !important;
    border-radius: 0px !important;
}
.modal_tabing .nav-tabs .nav-link.active{
    border-color: #fff #fff transparent;
    border-width:2px !important;
    color:#160f18;
}

.modal_tabing .nav-tabs .nav-link:hover{
    border-color: #fff #fff transparent;
        border-width: 2px !important;
        color: #160f18;
}

.modal-backdrop.show{
    background-color: #160f18;
}

.modal_tabing .tab-content p span, .card-main-content p span {
    display: block;
    height: 20px;
}
.modal_tabing .tab-content p {
    line-height: 1.4;
}
.testimonial-tab-inner {
    display: block;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
}
.testimonial-tab-inner span {
    display: block;
    padding-top: 35px;
    font-style: italic;
}
.modal_tabing .tab-content {
    max-height: 310px;
    overflow-y: auto;
    padding-right: 20px;
}

 /* width */
 .modal_tabing .tab-content::-webkit-scrollbar {
     width: 5px;
 }

 /* Track */
 .modal_tabing .tab-content::-webkit-scrollbar-track {
     background: #f1f1f1;
 }

 /* Handle */
 .modal_tabing .tab-content::-webkit-scrollbar-thumb {
     background: #888;
 }

 /* Handle on hover */
 .modal_tabing .tab-content::-webkit-scrollbar-thumb:hover {
     background: #555;
 }

 .cp{
    cursor: pointer;
 }

 .request-form-heading::after{
    left:50%;
    transform:translate(-50%, 0)
 }
 .requestHeader svg path {
     fill: #000;
 }

 .table-user td{
    vertical-align: middle;
 }
 .user-edit-ico{
    background-image: url("./images/material-icons/edit-icon.png");
    width: 30px;
    height: 30px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;
 }

  .user-delete-ico {
      background-image: url("./images/material-icons/delete-icon.png");
      width: 30px;
      height: 30px;
      display: block;
      background-repeat: no-repeat;
      background-position: center;
      background-repeat: no-repeat;
      object-fit: cover;
  }

 .lock-icon {
     background-image: url("./images/material-icons/lock-ico.png");
    width: 20px;
    height: 24px;
    display: block;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
 }
 .table thead th{
    padding-bottom: 28px;
 }
 .table-user th {
     font-weight: bold;
    font-family: 'lulocleanw01-oneboldregular';
 }
 .table-user th{
    border:none !important;
 }
 .card-user-table{
    border:none !important;
 }
 
  .card-user-table tbody tr td:nth-child(2) {
      font-weight: 500;
  }
  .empty-space{
    padding: 5px;
    border: none !important;
  }

.card-user-table tbody{
  max-height:450px;
  overflow-y:auto;
  display: block;
  border-bottom:1px solid #dee2e6;
}
.card-user-table thead, .card-user-table tbody tr {
    display: table;
    width: 100%;
  
}

.menu-ico-Coaching {
    background: url(./images/icon-coaching.png) no-repeat center / 30px;
    background-size: 30px;
}

.page__sidebar .navbar-nav a.navbar__link:hover .menu-ico-Coaching,
.navbar__link--active .menu-ico-Coaching {
    background: url(./images/icon-coaching-color.png) no-repeat center / 30px;
} 
/* 
.card--white .card-img-wrap {
    position: relative;
    height: 234px;
    width: 100%;
} */

.user-edit-panel .edit-delete-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
}

.dashboard-view .card--white .col-lg-6 {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.dashboard-view .theme-dark__btn.cust-pt-20 {
    margin-top: .7rem;
} 

.card-user-table thead{
 border-bottom:1px solid #dee2e6;
 padding-top:12px;
 padding-bottom: 25px;
}
.card-user-table tbody tr:nth-child(1) td{
    border-top:none !important
}


  .table-checkbox {
      display: block;
      position: relative;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }

  .table-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 25px;
    width: 25px;
    left: 0;
    top: 0;
    z-index: 34;
  }
  .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: #fff;
      border:1px solid #000
  }

  .table-checkbox:hover input~.checkmark {
      background-color: #fff;
  }

  .table-checkbox input:checked~.checkmark {
      background-color: #f8c8d0;
  }

  .checkmark:after {
      content: "";
      position: absolute;
      display: none;
  }

  .table-checkbox input:checked~.checkmark:after {
      display: block;
  }
  .table-checkbox .checkmark:after {
      left: 8px;
      top: 4px;
      width: 5px;
      height: 10px;
      border: solid #000;
      border-width: 0 1px 1px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
  }

  /* width */

.card-user-table tbody::-webkit-scrollbar {
      width: 5px;
  }

  /* Track */

.card-user-table tbody::-webkit-scrollbar-track {
      background: #f1f1f1;
  }

  /* Handle */

.card-user-table tbody::-webkit-scrollbar-thumb {
      background: #888;
  }

  /* Handle on hover */
  .card-user-table tbody::-webkit-scrollbar-thumb:hover {
      background: #555;
  }


  .user-sidebar-link p {
      font-size: 16px;
      color: #000;
      padding: 10px 0;
  }
  .user-sidebar_heading{
    border-bottom: 2px solid #dee2e6;
  }
  .user_create-modal .modal-body {
      padding:20px 90px 70px 90px;
  }
  .user_create-modal #sign-form {
      gap: 20px;
    justify-content: space-between;
  }
  .user_create-modal #sign-form .form-group {
      flex:0 0 31%
  }
.user_create-modal #sign-form .submit-block.form-group {
    flex: 0 0 31%;
}
.dashboard-view .feels-block .link-btn-wrap {
    text-align: center;
}

.font-weight-bold {
    text-transform: uppercase !important;
}

.content-posts-block.scroll-x-auto {
    height: 650px;
    width: 100%;
    overflow-x: auto;
}

.scroll-x-auto {
    scrollbar-width: thin;
    scrollbar-color: #979797 #f7f7f7;
}

.scroll-x-auto::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.scroll-x-auto::-webkit-scrollbar-track {
    background-clip: content-box;
    border: 0px solid transparent;
}

.scroll-x-auto::-webkit-scrollbar-thumb {
    background-color: #ff7f00;
}

.scroll-x-auto::-webkit-scrollbar-thumb:hover {
    background-color: #e67200;
}

.scroll-x-auto::-webkit-scrollbar-corner,
.scroll-x-auto::-webkit-scrollbar-track {
    background-color: #b0b7c4;
}

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #b0b7c4 rgb(255, 255, 255);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
}

*::-webkit-scrollbar-thumb {
    background-color: #b0b7c4;
    border-radius: 20px;
    border: 3px solid rgb(255, 255, 255);
}


.cust_shadow .you_feel {
    background-color: #fff;
    box-shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.04);
    -webkit-box-shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.04);
    -moz-box-shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.04);
    border-radius: 15px;
    padding: 34px 25px;
}

.greeting-card.cust_shadow {
    margin-right: 15px;
    margin-left: 10px;
}

.greeting-card.cust_shadow .cust-top {
    padding-top: 52px;
}


.cust_shadow .you_feel .col-md-8 {
    padding-right: 0px;
    padding-left: 0px;
}
.cust_shadow .you_feel .col-md-4 {
    padding-right: 0px;
    padding-left: 0px;
}

.cust_shadow .you_feel span {
    font-size: 16px;
    line-height: 0;
    padding-left: 5px;
}



.mood-feels-scroll {
    scrollbar-width: thin;
    scrollbar-color: #f8c8d0;
}

/* Works on Chrome, Edge, and Safari */
.mood-feels-scroll::-webkit-scrollbar {
    width: 12px;
}

.mood-feels-scroll::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
}

.mood-feels-scroll::-webkit-scrollbar-thumb {
    background-color: #f8c8d0;
    border-radius: 20px;
    border: 3px solid #f8c8d0;
}

.email-column{
    width: fit-content;
     word-break: break-all;
}


/* .page__sidebar .navbar-nav a.navbar__link:nth-child(2) span {
    background-size: 27px !important;
} */

@media (min-width: 1680px) and (max-width: 1880px) {

}

@media (min-width: 1600px) and (max-width: 1679px) {

}

@media (min-width: 1441px) and (max-width: 1599px) {

}

@media (min-width: 1366px) and (max-width: 1440px) {
.dashboard-view .theme-dark__btn.cust-pt-20 {
        margin-top: 1.6rem;
    }
.cust_shadow .you_feel {
    padding: 26px 15px;
}
.cust_shadow .you_feel h2 {
        font-size: 16px;
        line-height: 25px;
    }
.cust_shadow .you_feel span {
    font-size: 13px;
    line-height: 0;
    padding-left: 5px;
}
}

@media (min-width: 1242px) and (max-width: 1365px) {
.dashboard-view .theme-dark__btn.cust-pt-20 {
        margin-top: 1.6rem;
    }

    .cust_shadow .cust-top h2 {
            font-size: 14px;
        }
   .cust_shadow .you_feel h2 {
        font-size: 14px;
    }
    .cust_shadow .cust-top span {
        font-size: 13px;
    }
    .cust_shadow .you_feel span {
        font-size: 13px;
    }
    .cust_shadow .you_feel {
        padding: 34px 12px;
    }
    .cust_shadow .you_feel .theme-dark__btn {
        padding: 5px 12px !important;
        font-size: 10px !important;
    }
        .cust_shadow .you_feel .feel-cells img {
            width: 18px !important;
        }
    
        .cust_shadow .you_feel .col-md-4 {
            flex: 29% !important;
            ;
            max-width: 29% !important;
            ;
        }
    
        .cust_shadow .you_feel .col-md-8 {
            flex: 71% !important;
            ;
            max-width: 71% !important;
            ;
        }
}

@media (min-width: 992px) and (max-width: 1241px) {
.dashboard-view .theme-dark__btn.cust-pt-20 {
        margin-top: 0rem;
    }

    .cust_shadow .cust-top h2 {
        font-size: 11px;
    }

    .cust_shadow .you_feel h2 {
        font-size: 11px;
    }

    .cust_shadow .cust-top span {
        font-size: 10px;
    }
    .cust_shadow .you_feel span {
        font-size: 10px;
    }

    .cust_shadow .you_feel {
        padding: 34px 12px;
    }
    .cust_shadow .you_feel h3 {
        font-size: 10px;
    }
    .cust_shadow .you_feel .theme-dark__btn {
        padding: 5px 10px !important;
        font-size: 10px !important;
    }
    .greeting-card.cust_shadow .cust-top {
        padding-top: 34px;
    }

        .cust_shadow .you_feel .feel-cells img {
            width: 18px !important;
        }
    
        .cust_shadow .you_feel .col-md-4 {
            flex: 29% !important;
            ;
            max-width: 29% !important;
            ;
        }
    
        .cust_shadow .you_feel .col-md-8 {
            flex: 71% !important;
            ;
            max-width: 71% !important;
            ;
        }
}