@font-face {
    font-family: 'lulocleanw01-oneboldregular';
    src: url('fontsfree-net-lulo-clean-w01-one-webfont.woff2') format('woff2'),
         url('fontsfree-net-lulo-clean-w01-one-webfont.woff') format('woff');
    font-weight: 400;
}

@font-face {
    font-family: 'lulocleanw01-oneboldregular';
    src: url('fontsfree-net-lulo-clean-w01-one-bold-webfont.woff2') format('woff2'),
         url('fontsfree-net-lulo-clean-w01-one-bold-webfont.woff') format('woff');
    font-weight: 700;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');

@font-face {
    font-family: 'Montserrat', sans-serif;
    src: url('montserrat-black-webfont.woff2') format('woff2'),
         url('montserrat-black-webfont.woff') format('woff');
    font-weight: 900;
}

@font-face {
    font-family: 'Montserrat', sans-serif;
    src: url('montserrat-bold-webfont.woff2') format('woff2'),
         url('montserrat-bold-webfont.woff') format('woff');
    font-weight: 700;
}

@font-face {
    font-family: 'Montserrat', sans-serif;
    src: url('montserrat-extrabold-webfont.woff2') format('woff2'),
         url('montserrat-extrabold-webfont.woff') format('woff');
    font-weight: 800;
}

@font-face {
    font-family: 'Montserrat', sans-serif;
    src: url('montserrat-extralight-webfont.woff2') format('woff2'),
         url('montserrat-extralight-webfont.woff') format('woff');
    font-weight: 200;
}

@font-face {
    font-family: 'Montserrat', sans-serif;
    src: url('montserrat-light-webfont.woff2') format('woff2'),
         url('montserrat-light-webfont.woff') format('woff');
    font-weight: 300;
}

@font-face {
    font-family: 'Montserrat', sans-serif;
    src: url('montserrat-medium-webfont.woff2') format('woff2'),
         url('montserrat-medium-webfont.woff') format('woff');
    font-weight: 500;
}

@font-face {
    font-family: 'Montserrat', sans-serif;
    src: url('montserrat-regular-webfont.woff2') format('woff2'),
         url('montserrat-regular-webfont.woff') format('woff');
    font-weight: 400;
}

@font-face {
    font-family: 'Montserrat', sans-serif;
    src: url('montserrat-semibold-webfont.woff2') format('woff2'),
         url('montserrat-semibold-webfont.woff') format('woff');
    font-weight: 600;
}

@font-face {
    font-family: 'Montserrat', sans-serif;
    src: url('montserrat-thin-webfont.woff') format('woff'),
            url('montserrat-thin-webfont.woff') format('woff');
    font-weight: 100;
}